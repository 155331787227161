import { render, staticRenderFns } from "./supplierswhite.vue?vue&type=template&id=f9d1d516&scoped=true&"
import script from "./supplierswhite.vue?vue&type=script&lang=js&"
export * from "./supplierswhite.vue?vue&type=script&lang=js&"
import style0 from "./supplierswhite.vue?vue&type=style&index=0&id=f9d1d516&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9d1d516",
  null
  
)

export default component.exports